import DataTable, { createTheme } from 'react-data-table-component';

import { TableTheme, customTable } from '../../../Constants/ThemeO2T';
import Loading from '../../Basic/Loading';
import { formatDate } from '../../../Utils/util';
import AccessControl from '../../AccessControl';
import Alert from '../../Basic/AlertComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faDownload,
  faEdit,
  faQrcode,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { LinkStatus, LinkTypes } from 'src/Utils/types';
import { CSVLink } from 'react-csv';
import { Fragment } from 'react';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const csvheaders = [
  { label: 'email', key: 'email' },
  { label: 'link', key: 'link' },
];

const SharingMethodGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails } = data;

  const handleCopyAction = (event: any, sharingMethod: any) => {
    event.preventDefault();
    if (sharingMethod.type === LinkTypes.OPEN_LINK) {
      navigator.clipboard.writeText(sharingMethod.link);
    } else if (sharingMethod.type === LinkTypes.PORTAL_LINK) {
      navigator.clipboard.writeText(sharingMethod.surveyUUID);
    }
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (row.name ? row.name : ''),
      sortable: true,
      width: '30%',
    },
    {
      name: 'Type',
      selector: (row: any) => (row.type ? row.type : ''),
      sortable: true,
      width: '20%',
    },
    {
      name: 'Published at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.createdAt))) : 'N/A',
      sortable: true,
      width: '20%',
    },
    {
      name: 'Status',
      selector: (row: any) => (row.status ? row.status : ''),
      sortable: true,
      width: '20%',
    },
    {
      button: true,
      width: '10%',
      style: {
        justifyContent: 'right',
      },
      cell: (row: any) => (
        <div className='actions-col'>
          {row.type === LinkTypes.OPEN_LINK && (
            <Fragment>
              <button onClick={(event) => props.openQrModal(row)}>
                <FontAwesomeIcon icon={faQrcode} />
              </button>
              <span className='divider'>|</span>
            </Fragment>
          )}
          {row.type === LinkTypes.OPEN_LINK ||
          row.type === LinkTypes.PORTAL_LINK ? (
            <button onClick={(event) => handleCopyAction(event, row)}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          ) : (
            <CSVLink
              data={row.data.map(
                (userData: { email: string; uuid: string }) => {
                  return {
                    email: userData.email,
                    link: `${row.link}/${userData.uuid}`,
                  };
                }
              )}
              headers={csvheaders}
              filename={`${row.name}.csv`}
            >
              <FontAwesomeIcon icon={faDownload} />
            </CSVLink>
          )}
          <span className='divider'>|</span>
          {/* TODO: Uncomment this when implementing the analytics page for sharing methods
          <NavLink className='btn-theme' to={`sharing_method/view/${row._id}`}>
            <FontAwesomeIcon icon={faEye} />
          </NavLink> */}
          <button
            onClick={(event) => {
              event.preventDefault();
              props.openEditModal(row);
            }}
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
          <span className='divider'>|</span>
          <button
            className='btn-delete'
            onClick={(event) => {
              event.preventDefault();
              props.onOpenDisableModal(row);
            }}
          >
            <FontAwesomeIcon
              icon={row.status === LinkStatus.ACTIVE ? faToggleOn : faToggleOff}
            />
          </button>
        </div>
      ),
    },
  ];

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data?.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data?.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const handleRowClick = (row: any) => {
    props.openEditModal(row);
  };

  return (
    <>
      <AccessControl
        allowedPermissions={['list:licenses']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view licenses'
            }
          />
        )}
      >
        <DataTable
          columns={columns}
          data={data?.sharingMethods || []}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data?.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          onSort={data?.handleSortByColumn}
          paginationTotalRows={data?.total}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
          onRowClicked={handleRowClick}
          conditionalRowStyles={[
            {
              when: (row) => row,
              style: {
                cursor: 'pointer',
              },
            },
          ]}
        />
      </AccessControl>
    </>
  );
};

export default SharingMethodGrid;
