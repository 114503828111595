import React, { Fragment, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import QuestionTextInput from '../QuestionTextInput';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';

const RankingQuestion = ({ data }: any) => {
  const { choices, setChoices } = data;

  const [maxNumberOfChoices, setMaxNumberOfChoices] = useState<number>(
    data.question?.attributes?.numberOfChoices ||
      choices[Object.keys(choices)[0]]?.length
  );

  const { control, formState, register, reset, setValue, getValues } =
    useFormContext<any>();

  const { errors } = formState;

  useEffect(() => {
    const initializeChoices = () => {
      const choices: any = {};
      data.questionnaire?.supportedLanguages?.forEach((language: any) => {
        choices[language.code] = [
          { _id: '1', content: 'Choice 1' },
          { _id: '2', content: 'Choice 2' },
        ];
      });
      return choices;
    };

    const setChoiceValues = (question: any) => {
      let choices: any = {};

      if (question?.questions?.length > 0) {
        question.questions.forEach((item: any) => {
          if (item.text.choices) {
            choices[item.language.code] = item.text.choices;
          } else {
            choices = initializeChoices();
          }
        });
      } else {
        choices = initializeChoices();
      }

      setChoices(choices);
    };

    const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
      const formData: any = {};

      questionnaire?.supportedLanguages?.forEach((language: any) => {
        const question = questions.questions?.find(
          (question: any) => question?.language?.code === language.code
        );
        formData[language.code] = {
          id: question?.text?.id || null,
          label: question?.text?.main || null,
          sub: question?.text?.sub || null,
        };
      });

      return formData;
    };

    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );

    setChoiceValues(data.question);

    reset({
      ...questionsData,
      optional: data.question?.attributes?.optional,
      hideBackButton: data.question?.attributes?.hideBackButton,
    });
  }, [data.question, data.questionnaire, setChoices, reset]);

  const handleFocus = (event: any) => {
    event.currentTarget?.select();
  };

  useEffect(() => {
    setValue('numberOfChoices', maxNumberOfChoices);
  }, [maxNumberOfChoices, setValue]);

  const handleChoiceValue = (value: string, item: any, language: any) => {
    const updatedChoice = choices[language.code].map((choice: any) => {
      if (choice._id === item._id) {
        return {
          ...choice,
          content: value,
        };
      }
      return choice;
    });
    const newChoices = { ...choices };
    newChoices[language.code] = updatedChoice;
    setChoices(newChoices);
  };

  const handleAddChoices = (event: any) => {
    event.preventDefault();

    const { numberOfChoices } = getValues();

    const defaultLanguage = data.questionnaire?.defaultLanguage;
    const choiceCount = choices[defaultLanguage.code].length;

    if (choiceCount < 15) {
      const newState = { ...choices };

      newState[defaultLanguage.code] = [
        ...choices[defaultLanguage.code],
        {
          _id: `${choices[defaultLanguage.code].length + 1}`,
          content: 'New choice',
        },
      ];
      const updatedChoiceCount = newState[Object.keys(newState)[0]]?.length;
      setMaxNumberOfChoices(
        Number(numberOfChoices) < Number(updatedChoiceCount - 1)
          ? Number(numberOfChoices)
          : updatedChoiceCount
      );
      setChoices(newState);
    }
  };

  const onDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      choices[language.code],
      result.source.index,
      result.destination.index
    );

    const updatedChoices = {
      ...choices,
      [language.code]: newOrder,
    };
    setChoices(updatedChoices);
  };

  const deleteChoice = (event: any, id: number, language: any) => {
    event.preventDefault();
    const updatedChoices = {
      ...choices,
      [language.code]: choices[language.code].filter(
        (item: any) => item._id !== id
      ),
    };

    const { numberOfChoices } = getValues();

    const updatedChoiceCount =
      updatedChoices[Object.keys(updatedChoices)[0]]?.length;

    setMaxNumberOfChoices(
      Number(numberOfChoices) < Number(updatedChoiceCount)
        ? Number(numberOfChoices)
        : updatedChoiceCount
    );
    setChoices(updatedChoices);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='row m-0'>
                <div className='col col-8 pt-4'>
                  <div className='form-group'>
                    <input
                      className={`${
                        errors[data.questionnaire?.defaultLanguage.code]?.id
                          ? 'is-invalid'
                          : ''
                      } italic-input half-width-form-control `}
                      placeholder='Question label...'
                      type='text'
                      {...register(
                        `${data.questionnaire?.defaultLanguage.code}.id`,
                        {
                          required: true,
                        }
                      )}
                      disabled={data.isLoading}
                    />
                  </div>
                  <div className='form-group'>
                    <div
                      className={`bottom-border-input italic-input ${
                        errors[data.questionnaire?.defaultLanguage.code]?.label
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.label`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Start typing question text...'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='no-border-input italic-input'>
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.sub`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Add description to your question'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row m-0'>
                <div className='col col-8 pt-4 pb-5'>
                  <DragDropContext
                    onDragEnd={(result) =>
                      onDragEnd(result, data.questionnaire?.defaultLanguage)
                    }
                  >
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {choices?.[
                            data.questionnaire?.defaultLanguage.code
                          ]?.map((item: any, index: number) => {
                            return (
                              <Draggable
                                draggableId={item._id}
                                index={index}
                                key={item._id}
                              >
                                {(provided) => (
                                  <div
                                    key={index}
                                    className='choices-list'
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    style={{
                                      position: 'relative',
                                    }}
                                  >
                                    <div className='choice-wrapper'>
                                      <QuestionTextInput
                                        style={{
                                          display: 'inline-block',
                                          width: '100%',
                                        }}
                                        onChange={(value: string) => {
                                          const updatedValue =
                                            sanitizeQuillEditorOutput(value);
                                          return handleChoiceValue(
                                            updatedValue,
                                            item,
                                            data.questionnaire?.defaultLanguage
                                          );
                                        }}
                                        onFocus={handleFocus}
                                        value={item.content}
                                        disabled={data.isLoading}
                                      />
                                    </div>

                                    <button
                                      style={{
                                        position: 'absolute',
                                        left: '90%',
                                      }}
                                      className='btn-delete without-bdr'
                                      onClick={(event) =>
                                        deleteChoice(
                                          event,
                                          item._id,
                                          data.questionnaire?.defaultLanguage
                                        )
                                      }
                                      disabled={data.isLoading}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        </div>
                      )}
                    </Droppable>
                    <button
                      className='btn-add-new'
                      onClick={(event: any) => handleAddChoices(event)}
                    >
                      + Add new
                    </button>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='optional'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Optional</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Hide back button</label>
          </div>
        </div>
      )}
    </>
  );
};

export default RankingQuestion;
