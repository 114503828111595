import React, { useEffect, useState } from 'react';
import UsersGrid from '../Components/UserModule/UserGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Loading from '../Components/Basic/Loading';
import AddEditModal from '../Components/UserModule/AddEditUserModal';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  ADD_USER,
  DELETE_USER,
  GET_PAGINATION_USERS,
  UPDATE_USER,
} from '../Query';
import {
  setAssignedLicenses,
  setUserDataToStore,
} from '../States/Actions/usersAction';
import DeleteUserModal from '../Components/UserModule/DeleteUserModal';
import { toast, ToastContainer } from 'react-toastify';
import {
  GET_LICENSES_BY_IDS,
  GET_PAGINATED_LICENSES,
} from '../Query/licenses.query';
import { setLicenseDataToStore } from '../States/Actions/licenseAction';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';

const Users: React.FunctionComponent<any> = (props: any) => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sort, setsSort] = useState('created_at:-1');
  const [query, setQuery] = useState(
    `name:*${props.searchData.searchTerm || ''}* OR email:*${
      props.searchData.searchTerm || ''
    }*`
  );
  const [editUser, setEditUser] = useState<any>(null);
  const [deleteUser, setDeleteUser] = useState(null);

  useEffect(() => {
    setQuery(
      `name:*${props.searchData.searchTerm || ''}* OR email:*${
        props.searchData.searchTerm || ''
      }*`
    );
  }, [props.searchData]);

  const [licensePaginationDetails, setLicensePaginationDetails] = useState<any>(
    {
      limit: 10,
      page: 1,
      sortBy: 'NAME',
      sortOrder: 'ASC',
    }
  );

  function toggleCreateModal() {
    setOpenAddModal(!openAddModal);
  }
  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handlePageChange = (cPage: number, cPerPage: number, sort: string) => {
    setPage(cPage);
    setPerPage(cPerPage);
    setsSort(sort);
  };

  const handleEditUserSelect = (user: any) => {
    toggleEditModal();
    setEditUser(user);
  };

  const handleDeleteUserSelect = (user: any) => {
    toggleDeleteModal();
    setDeleteUser(user);
  };

  const loadLicenseOptions = (paginationDetails: any) => {
    setLicensePaginationDetails(paginationDetails);
  };

  const handleLicenseDropdownScroll = () => {
    setLicensePaginationDetails({
      ...licensePaginationDetails,
      limit: licensePaginationDetails.limit + 10,
    });
  };

  const [loadLicensesByIds] = useLazyQuery(GET_LICENSES_BY_IDS, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      props.setAssignedLicenses(completedData?.licensesByIds);
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  const [
    deleteSelectedUser,
    { error: deleteUserError, loading: deleteUserLoading },
  ] = useMutation(DELETE_USER, {
    refetchQueries: getRefetchQueries({
      page,
      per_page: perPage,
      sort,
      q: query,
    }),
    errorPolicy: 'none',
    onCompleted: () => {
      toggleDeleteModal();
      toast.success('User deleted successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [updateSelectedUser, { error: updateError, loading: editUserLoading }] =
    useMutation(UPDATE_USER, {
      refetchQueries: [
        ...getRefetchQueries({ page, per_page: perPage, sort, q: query }),
        {
          query: GET_PAGINATED_LICENSES,
          variables: { limit: props.licenses?.length },
        },
      ],
      errorPolicy: 'none',
      onCompleted: (completedData: any) => {
        const updatedUser = completedData.updateQmsuser;
        const updatedUserLicenses =
          completedData.updateQmsuser.app_metadata?.licenses || [];
        const currentUserLicenses = props.user?.licenses?.map(
          (license: any) => license._id
        );
        if (
          updatedUser?.user_id === props.user.user_id &&
          !_.isEqual(updatedUserLicenses, currentUserLicenses)
        ) {
          loadLicensesByIds({
            variables: {
              ids: updatedUserLicenses,
            },
          });
        }

        toggleEditModal();
        toast.success('User updated successfully');
      },
      onError: (error: any) => {
        toast.error(error.message);
        console.error(error);
      },
    });

  const [addUser, { error: addUserError, loading: addUserLoading }] =
    useMutation(ADD_USER, {
      refetchQueries: getRefetchQueries({
        page,
        per_page: perPage,
        sort,
        q: query,
      }),
      errorPolicy: 'none',
      onCompleted: () => {
        toggleCreateModal();
        toast.success('User added successfully');
      },
      onError: (error: any) => {
        toast.error(error.message);
        console.error(error);
      },
    });

  const { loading, error } = useQuery(GET_PAGINATION_USERS, {
    variables: { page, per_page: perPage, sort, q: query },
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        props.setUserDataToStore(completedData.users);
      }
    },
  });

  useQuery(GET_PAGINATED_LICENSES, {
    variables: { ...licensePaginationDetails },
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        props.setLicenseDataToStore(completedData.licenses);
      }
    },
  });

  return (
    <div className='users-screen'>
      <AccessControl
        allowedPermissions={['view:users_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view users page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>Users</h2>
          </div>
          <div className='page-header__actions'>
            <AccessControl allowedPermissions={['create:user']}>
              <button onClick={toggleCreateModal}>
                Add user
                <span>
                  <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                </span>
              </button>
            </AccessControl>
          </div>
        </div>
        {openAddModal && (
          <AddEditModal
            modalIsOpen={openAddModal}
            toggleModal={toggleCreateModal}
            modalTitle={'Add User'}
            error={addUserError}
            onSubmit={addUser}
            isLoading={addUserLoading}
          />
        )}
        {openEditModal && (
          <AddEditModal
            modalIsOpen={openEditModal}
            toggleModal={toggleEditModal}
            modalTitle={'Edit User'}
            modalData={{
              ...editUser,
              licenses: props.licenses,
            }}
            error={updateError}
            onSubmit={updateSelectedUser}
            loadLicenseOptions={loadLicenseOptions}
            handleLicenseDropdownScroll={handleLicenseDropdownScroll}
            isLoading={editUserLoading}
          />
        )}
        {openDeleteModal && (
          <DeleteUserModal
            modalIsOpen={openDeleteModal}
            toggleModal={toggleDeleteModal}
            modalTitle={'Delete User'}
            modalData={deleteUser}
            error={deleteUserError}
            onDelete={deleteSelectedUser}
            isLoading={deleteUserLoading}
          />
        )}
        <div className='grid-wrapper'>
          <UsersGrid
            data={{
              users: props.users,
              total: props.total,
              page,
              perPage,
              loading,
              error,
              sort,
              handlePageChange,
              handleEditUserSelect,
              handleDeleteUserSelect,
            }}
          />
        </div>
        <ToastContainer position='bottom-right' theme='colored' />
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    users: state.users?.users,
    licenses: state.licenses.results,
    total: state.users?.total,
    searchData: state.searchData,
  };
};

const getRefetchQueries = (variables: {
  page: number;
  per_page: number;
  sort: string;
  q: string;
}) => {
  return [
    {
      query: GET_PAGINATION_USERS,
      variables,
    },
  ];
};

export default connect(mapStateToProps, {
  setUserDataToStore,
  setLicenseDataToStore,
  setAssignedLicenses,
})(
  withAuthenticationRequired(Users, {
    onRedirecting: () => <Loading />,
  })
);
