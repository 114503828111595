import HTMLEditor from '../../HTMLEditor';

const CustomPageQuestionView = ({ data }: any) => {
  const { questionnaire, question } = data;
  const defaultLanguage = questionnaire.defaultLanguage;
  const questionData = question.questions.find(
    (questionData: any) => questionData.language?.code === defaultLanguage?.code
  );

  return (
    <div>
      <div className='form-group'>
        <label>Selected language</label>
        <div>{defaultLanguage.name}</div>
      </div>
      <div className='form-group'>
        <div className='html-editor'>
          <HTMLEditor onChange={() => {}} value={questionData?.text?.html} />
        </div>
      </div>
    </div>
  );
};

export default CustomPageQuestionView;
