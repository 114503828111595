import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { IPage } from '../Interfaces';
import {
  displayToastMessage,
  setLicenseDataToStore,
} from '../States/Actions/licenseAction';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Components/Basic/Loading';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';
import StyleTemplatesGrid from 'src/Components/StyleTemplatesModule/StyleTemplatesGrid';
import { DeleteStyleTemplatesModal } from 'src/Components/StyleTemplatesModule/DeleteStyleTemplatesModal';
import {
  ADD_STYLE_TEMPLATE,
  DELETE_STYLE_TEMPLATE,
  EDIT_STYLE_TEMPLATE,
  GET_PAGINATED_STYLE_TEMPLATES,
} from 'src/Query/styleTemplates.query';
import { setStyleTemplatesToStore } from 'src/States/Actions/styleTemplateAction';
import AddEditStyleTemplateModal from 'src/Components/StyleTemplatesModule/AddStyleTemplateModal';
import { GET_PAGINATED_LICENSES } from 'src/Query/licenses.query';
import { Role } from 'src/Utils/types';
import { useRouteMatch } from 'react-router';
import TopSearch from 'src/Components/QuestionnaireModule/TopSearch';
import { setProduct } from 'src/States/Actions/productAction';
import { GET_PAGINATED_PRODUCTS } from 'src/Query/products.query';

const StyleTemplates: React.FunctionComponent<IPage> = (props: any) => {
  const {
    setStyleTemplatesToStore,
    licenseToast,
    styleTemplates,
    total,
    next,
    previous,
    displayToastMessage,
    searchData,
    licenses,
    user,
    setLicenseDataToStore,
  } = props;
  const { path } = useRouteMatch();
  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editStyleTemplateModal, setEditStyleTemplateModal] = useState(false);
  const [deleteStyleTemplate, setDeleteStyleTemplate] = useState(null);
  const [openAddEditStyleTemplateModal, setOpenAddEditStyleTemplateModal] =
    useState(false);
  const [selectedStlyeTemplate, setSelectedStyleTemplate] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [licensePaginationDetails, setLicensePaginationDetails] = useState<any>(
    {
      limit: 10,
      page: 1,
      sortBy: 'NAME',
      sortOrder: 'ASC',
    }
  );
  const [isProductModule, setIsProductModule] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [productPagination, setProductPagination] = useState<any>({
    limit: 10,
    page: 1,
  });
  const [productList, setProductList] = useState<any[]>([]);
  const [itemsList, setItemsList] = useState<any[]>([]);

  const [fetchProducts] = useLazyQuery(GET_PAGINATED_PRODUCTS, {
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setProductList(completedData.products?.results);
        setItemsList(
          completedData.products?.results.map((product: any) => {
            return {
              value: product._id,
              label: product.name,
            };
          })
        );
      }
    },
  });

  const [fetchStyleTemplates, { loading, error }] = useLazyQuery(
    GET_PAGINATED_STYLE_TEMPLATES,
    {
      errorPolicy: 'all',
      onCompleted: (completedData) => {
        if (completedData) {
          setStyleTemplatesToStore(completedData.styleTemplates);
        }
      },
    }
  );

  useEffect(() => {
    fetchStyleTemplates({
      variables: getParametersForStyleTemplates(
        {
          ...paginationDetails,
          searchTerm: searchData.searchTerm,
        },
        selectedProduct,
        isProductModule
      ),
    });
  }, [
    paginationDetails,
    fetchStyleTemplates,
    isProductModule,
    searchData,
    selectedProduct,
  ]);

  useEffect(() => {
    if (isProductModule) {
      fetchProducts({
        variables: { ...productPagination },
      });
    }
  }, [fetchProducts, isProductModule, productPagination]);

  useEffect(() => {
    setIsProductModule(path.includes('products/templates'));
  }, [path]);

  useEffect(() => {
    setIsAdmin(user.roles.indexOf(Role.ADMIN) !== -1);
  }, [user]);

  const getParametersForStyleTemplates = (
    params: any,
    productId: string | null,
    isProductModule: boolean
  ) => {
    const obj = { ...params, isProductItem: isProductModule };

    if (isProductModule) obj.productId = productId;

    return obj;
  };

  const handleScroll = (event: any) => {
    setProductPagination({
      ...productPagination,
      limit: productPagination.limit + 10,
    });
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const toggleAddEditModal = (isEdit = false) => {
    setEditStyleTemplateModal(isEdit);
    setOpenAddEditStyleTemplateModal(!openAddEditStyleTemplateModal);
  };

  useQuery(GET_PAGINATED_LICENSES, {
    variables: { ...licensePaginationDetails },
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setLicenseDataToStore(completedData.licenses);
      }
    },
  });

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const handleDeleteStyleTemplateSelect = (license: any) => {
    setDeleteStyleTemplate(license);
    toggleDeleteModal();
  };

  const handleStyleTemplateSelect = (row: any) => {
    const selectedStyleTemplate = styleTemplates.find(
      (styleTemplate: any) => styleTemplate._id === row._id
    );
    toggleAddEditModal(true);
    setSelectedStyleTemplate(selectedStyleTemplate);
  };

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
      };
    });
  }, [searchData]);

  useEffect(() => {
    if (licenseToast) {
      const toastType = licenseToast.success ? 'success' : 'error';
      toast[toastType](licenseToast.message);
      displayToastMessage(null);
    }
  }, [licenseToast, displayToastMessage]);

  const [
    addStyleTemplate,
    { error: addStyleTemplateError, loading: addStyleTemplateLoading },
  ] = useMutation(ADD_STYLE_TEMPLATE, {
    refetchQueries: [
      {
        query: GET_PAGINATED_STYLE_TEMPLATES,
        variables: getParametersForStyleTemplates(
          {
            limit: 10,
            page: 1,
            searchTerm: '',
          },
          selectedProduct,
          isProductModule
        ),
      },
    ],
    errorPolicy: 'none',
    onCompleted: () => {
      toast.success('Style template added successfully');
      fetchStyleTemplates({
        variables: getParametersForStyleTemplates(
          {
            limit: 10,
            page: 1,
            searchTerm: '',
          },
          selectedProduct,
          isProductModule
        ),
      });

      toggleAddEditModal();
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [
    editStyleTemplate,
    { error: editStyleTemplateError, loading: editStyleTemplateLoading },
  ] = useMutation(EDIT_STYLE_TEMPLATE, {
    errorPolicy: 'none',
    onCompleted: () => {
      toast.success('Style template updated successfully');
      fetchStyleTemplates({
        variables: getParametersForStyleTemplates(
          {
            limit: 10,
            page: 1,
            searchTerm: '',
          },
          selectedProduct,
          isProductModule
        ),
      });
      toggleAddEditModal();
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [
    deleteSelectedStyleTemplate,
    { error: deleteStyleTemplateError, loading: deleteStyleTemplateLoading },
  ] = useMutation(DELETE_STYLE_TEMPLATE, {
    errorPolicy: 'none',
    onCompleted: () => {
      toast.success('Style template deleted successfully');
      fetchStyleTemplates({
        variables: getParametersForStyleTemplates(
          {
            limit: 10,
            page: 1,
            searchTerm: '',
          },
          selectedProduct,
          isProductModule
        ),
      });
      toggleDeleteModal();
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const handleSubmit = (params: any) => {
    if (editStyleTemplateModal) {
      editStyleTemplate(params);
    } else {
      addStyleTemplate(params);
    }
  };

  const loadLicenseOptions = (paginationDetails: any) => {
    if (isAdmin) {
      setLicensePaginationDetails(paginationDetails);
    }
  };

  const handleLicenseDropdownScroll = () => {
    if (isAdmin) {
      setLicensePaginationDetails({
        ...licensePaginationDetails,
        limit: licensePaginationDetails.limit + 10,
      });
    }
  };

  return (
    <div className='templates-screen'>
      {isProductModule && (
        <TopSearch
          value={selectedProduct}
          onChange={(selectedProduct: any) => {
            setSelectedProduct(selectedProduct);
            props.setProduct(
              productList.find(
                (product: any) => product._id === selectedProduct
              )
            );
          }}
          itemList={itemsList}
          isProductModule={isProductModule}
          onScroll={handleScroll}
        />
      )}
      <AccessControl
        allowedPermissions={['view:licenses_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view templates page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>{`${isProductModule ? 'Product ' : ''}Templates`}</h2>
          </div>
          <div className='page-header__actions'>
            <button
              onClick={(event: any) => {
                event.preventDefault();
                setSelectedStyleTemplate(null);
                toggleAddEditModal();
              }}
              className='btn-theme'
            >
              {`Add ${isProductModule ? 'Product ' : ''}Template`}

              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
        {openDeleteModal && (
          <DeleteStyleTemplatesModal
            modalIsOpen={openDeleteModal}
            toggleModal={toggleDeleteModal}
            modalTitle={'Delete Style Template'}
            modalData={deleteStyleTemplate}
            error={deleteStyleTemplateError}
            onDelete={deleteSelectedStyleTemplate}
            isLoading={deleteStyleTemplateLoading}
          />
        )}
        {openAddEditStyleTemplateModal && (
          <AddEditStyleTemplateModal
            modalIsOpen={openAddEditStyleTemplateModal}
            toggleModal={toggleAddEditModal}
            isEdit={editStyleTemplateModal}
            modalData={{
              styles: selectedStlyeTemplate,
              licenses: isAdmin ? licenses : user.licenses,
              selectedStlyeTemplate,
              user,
            }}
            onSubmit={handleSubmit}
            error={addStyleTemplateError || editStyleTemplateError}
            isLoading={addStyleTemplateLoading || editStyleTemplateLoading}
            loadLicenseOptions={loadLicenseOptions}
            handleLicenseDropdownScroll={handleLicenseDropdownScroll}
            isProductModule={isProductModule}
            productsList={itemsList}
            handleProductDropdownScroll={handleScroll}
          />
        )}
        <div className='grid-wrapper'>
          <StyleTemplatesGrid
            data={{
              styleTemplates: isProductModule
                ? selectedProduct
                  ? styleTemplates
                  : []
                : styleTemplates,
              total: total,
              loading,
              error,
              paginationDetails,
              next: next,
              previous: previous,
              handlePageChange,
              handleSortByColumn,
              handleDeleteStyleTemplateSelect,
              setSelectedStyleTemplate,
              handleStyleTemplateSelect,
            }}
          />
        </div>
        <ToastContainer position='bottom-right' theme='colored' />
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    licenses: state.licenses.results,
    user: state.user,
    styleTemplates: state.styleTemplates.styleTemplates,
    hasNext: state.styleTemplates.hasNext,
    next: state.styleTemplates.next,
    hasPrevious: state.styleTemplates.hasPrevious,
    previous: state.styleTemplates.previous,
    total: state.styleTemplates.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setStyleTemplatesToStore,
  displayToastMessage,
  setLicenseDataToStore,
  setProduct,
})(
  withAuthenticationRequired(StyleTemplates, {
    onRedirecting: () => <Loading />,
  })
);
