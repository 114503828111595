import React, { useCallback, useEffect } from 'react';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionTextInput from '../QuestionTextInput';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';

const RatingQuestion = (props: any) => {
  const { data } = props;
  const { choices, setChoices } = data;

  const { control, formState, register, reset, watch, setValue, getValues } =
    useFormContext<any>();

  const { errors } = formState;
  const [isNPS, customAnswerText, numberOfChoices] = watch([
    'isNPS',
    'customAnswerText',
    'numberOfChoices',
  ]);

  const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
    const formData: any = {};

    questionnaire?.supportedLanguages?.forEach((language: any) => {
      const question = questions.questions?.find(
        (question: any) => question?.language?.code === language.code
      );
      formData[language.code] = {
        id: question?.text?.id || null,
        label: question?.text?.main || null,
        sub: question?.text?.sub || null,
        choices: question?.text?.ratings || [],
      };
    });
    return formData;
  };

  const handleFocus = (event: any) => {
    event.currentTarget?.select();
  };

  const initializeChoices = useCallback(
    (questionData?: any) => {
      if (questionData) {
        const initalChoices: any = {};
        Object.keys(questionData).forEach((key: string) => {
          initalChoices[key] = questionData[key].choices;
        });
        setChoices(initalChoices);
      }
    },
    [setChoices]
  );

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );

    initializeChoices(questionsData);
    reset({
      ...questionsData,
      optional: data.question?.attributes?.optional,
      showNavigation: data.question?.attributes?.showNavigation,
      isNPS: data.question?.attributes?.isNPS,
      customAnswerText: !data.question?.attributes?.showAsNumbers,
      numberOfChoices: data.question?.attributes?.numberOfChoices,
    });
  }, [data.question, data.questionnaire, reset, initializeChoices]);

  const addChoice = useCallback(
    (choices: any, languageCode?: string) => {
      const language = languageCode || data.questionnaire?.defaultLanguage.code;
      const choiceCount = choices[language]?.length;

      if (choiceCount < 11) {
        const newState = { ...choices };

        data.questionnaire?.supportedLanguages?.forEach((language: any) => {
          newState[language.code] = [
            ...choices[language.code],
            {
              rating: `${choices[language.code].length + 1}`,
              label: choices[language.code].length + 1,
            },
          ];
        });
        return newState;
      }
      return choices;
    },
    [data.questionnaire]
  );

  const deleteChoice = () => {
    let updatedChoices = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      updatedChoices = {
        ...updatedChoices,
        [language.code]: choices[language.code].filter(
          (choice: any, index: number) => index !== numberOfChoices - 1
        ),
      };
    });

    return updatedChoices;
  };

  const addMultipleChoices = (choices: any, choiceCount: number) => {
    const updatedChoices = { ...choices };
    data.questionnaire?.supportedLanguages?.forEach(
      ({ code }: { code: string }) => {
        while (updatedChoices[code].length < choiceCount) {
          const choiceList = addChoice(updatedChoices, code);
          updatedChoices[code] = choiceList[code];
        }
      }
    );

    return updatedChoices;
  };

  const handleNPSChange = (event: any) => {
    if (event) {
      const updatedChoices = addMultipleChoices(choices, 11);
      setChoices(updatedChoices);
      setValue('numberOfChoices', 11);
    }
    setValue('isNPS', event);
  };

  const onDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      choices[language.code],
      result.source.index,
      result.destination.index
    );

    const updatedChoices = {
      ...choices,
      [language.code]: newOrder,
    };
    setChoices(updatedChoices);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleChoiceValue = (value: string, item: any, language: any) => {
    const updatedChoice = choices[language.code].map((choice: any) => {
      if (choice.rating === item.rating) {
        return {
          ...choice,
          label: value,
        };
      }
      return choice;
    });
    const newChoices = { ...choices };
    newChoices[language.code] = updatedChoice;
    setChoices(newChoices);
  };

  const handleChoiceChange = (event: any) => {
    const value = event.target.value;
    let updatedChoices = {};
    if (Number(value) > numberOfChoices) {
      updatedChoices = addChoice(choices);
    } else {
      updatedChoices = deleteChoice();
    }
    setValue('numberOfChoices', value);
    setChoices(updatedChoices);
  };

  const getInitialChoiceObject = () => {
    const initialChoices: any = {};
    data.questionnaire?.supportedLanguages?.forEach((language: any) => {
      initialChoices[language.code] = [];
    });
    return initialChoices;
  };

  const handleCustomAnswerTextOptionChange = (event: boolean) => {
    if (event) {
      const { numberOfChoices } = getValues();
      const questionsData = getQuestionsDataForForm(
        data.question,
        data.questionnaire
      );
      const languageCode = Object.keys(questionsData)[0];

      if (!questionsData?.[languageCode]?.choices?.length) {
        const initChoices = getInitialChoiceObject();
        const updatedChoices = addMultipleChoices(initChoices, numberOfChoices);
        setChoices(updatedChoices);
      }
    }
    setValue('customAnswerText', event);
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='row m-0'>
                <div className='col col-8 pt-4'>
                  <div className='form-group'>
                    <input
                      className={`${
                        errors[data.questionnaire?.defaultLanguage?.code]?.id
                          ? 'is-invalid'
                          : ''
                      } italic-input half-width-form-control `}
                      placeholder='Question label...'
                      type='text'
                      {...register(
                        `${data.questionnaire?.defaultLanguage?.code}.id`,
                        {
                          required: true,
                        }
                      )}
                      disabled={data.isLoading}
                    />
                  </div>
                  <div className='form-group'>
                    <div
                      className={`bottom-border-input italic-input ${
                        errors[data.questionnaire?.defaultLanguage.code]?.label
                          ? 'is-invalid'
                          : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.label`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Start typing question text...'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='no-border-input italic-input'>
                      <Controller
                        control={control}
                        name={`${data.questionnaire?.defaultLanguage.code}.sub`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Add description to your question'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {customAnswerText && (
                <div className='row m-0'>
                  <div className='col col-8 pt-4 pb-5'>
                    <DragDropContext
                      onDragEnd={(result) =>
                        onDragEnd(result, data.questionnaire?.defaultLanguage)
                      }
                    >
                      <Droppable droppableId='droppable'>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {choices?.[
                              data.questionnaire?.defaultLanguage?.code
                            ]?.map((item: any, index: number) => {
                              return (
                                <Draggable
                                  draggableId={item.rating.toString()}
                                  index={index}
                                  key={item.rating}
                                >
                                  {(provided) => (
                                    <div
                                      key={index}
                                      className='choices-list'
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <div className='choice-wrapper'>
                                        <QuestionTextInput
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                          }}
                                          placeholder={item.label}
                                          onChange={(value: string) => {
                                            const updatedValue =
                                              sanitizeQuillEditorOutput(value);
                                            return handleChoiceValue(
                                              updatedValue,
                                              item,
                                              data.questionnaire
                                                ?.defaultLanguage
                                            );
                                          }}
                                          onFocus={handleFocus}
                                          value={item.label}
                                          disabled={data.isLoading}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='optional'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Optional</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Hide back button</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='isNPS'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={handleNPSChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Treat as NPS</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='customAnswerText'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={handleCustomAnswerTextOptionChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Custom answer text</label>
          </div>
          {!isNPS && (
            <div className='form-group'>
              <label className='mb-1'>Number of choices</label>
              <Controller
                control={control}
                name='numberOfChoices'
                rules={{ required: true }}
                defaultValue={10}
                render={({ field: { value } }) => (
                  <input
                    className={`${
                      errors?.numberOfChoices ? 'is-invalid' : ''
                    } italic-input`}
                    onChange={(event) => {
                      handleChoiceChange(event);
                    }}
                    type='number'
                    min={2}
                    max={11}
                    value={value}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RatingQuestion;
