import { useLazyQuery } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { GET_PAGINATED_SURVEY_VERSIONS } from 'src/Query/surveyVersions.query';
import { setSurveyVersionDataToStore } from 'src/States/Actions/surveyVersionAction';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';
import Loading from '../Basic/Loading';
import SurveyVersionsGrid from './SurveyVersionsGrid';

const SurveyVersionsList = ({
  setSurveyVersionDataToStore,
  surveyVersions,
  total,
  next,
  previous,
  searchData,
  questionnaire,
}: any) => {
  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const [loadSurveyVersions, { error, loading }] = useLazyQuery(
    GET_PAGINATED_SURVEY_VERSIONS,
    {
      variables: {
        ...paginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      },
      errorPolicy: 'all',
      onCompleted: (completedData) => {
        if (completedData) {
          setSurveyVersionDataToStore(completedData.surveyVersions);
        }
      },
    }
  );

  useEffect(() => {
    loadSurveyVersions({
      variables: {
        ...paginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      },
    });
  }, [loadSurveyVersions, paginationDetails, questionnaire, searchData]);

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      };
    });
  }, [searchData, questionnaire]);

  return (
    <div className='license-screen'>
      <AccessControl
        allowedPermissions={['view:surveys_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view survey versions'
            }
          />
        )}
      >
        <div className='grid-wrapper'>
          <SurveyVersionsGrid
            data={{
              surveyVersions: surveyVersions,
              total: total,
              loading,
              error,
              paginationDetails,
              next: next,
              previous: previous,
              handlePageChange,
              handleSortByColumn,
            }}
          />
        </div>
        <ToastContainer position='bottom-right' theme='colored' />
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    surveyVersions: state.surveyVersions.results,
    questionnaire: state.questionnaire,
    hasNext: state.surveyVersions.hasNext,
    next: state.surveyVersions.next,
    hasPrevious: state.surveyVersions.hasPrevious,
    previous: state.surveyVersions.previous,
    total: state.surveyVersions.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, { setSurveyVersionDataToStore })(
  withAuthenticationRequired(SurveyVersionsList, {
    onRedirecting: () => <Loading />,
  })
);
