import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QuestionTypes } from '../../../Utils/types';
import AccessControl from '../../AccessControl';
import ChoicesQuestion from './ChoicesQuestion';
import ConditionalQuestion from './ConditionalQuestion';
import CustomFinalPage from './CustomFinalPage';
import CustomStartingPage from './CustomStartingPage';
import FinalPage from './FinalPage';
import FormQuestion from './FormQuestion';
import InfoPage from './InfoPage';
import MatrixQuestion from './MatrixQuestion';
import OpenQuestion from './OpenQuestion';
import {
  formatChoicesQuestionData,
  formatCustomHTMLPageQuestionData,
  formatFinalQuestionData,
  formatFormQuestionData,
  formatInfoQuestionData,
  formatMatrixChoiceQuestionData,
  formatMatrixRatingQuestionData,
  formatOpenQuestionData,
  formatRankingQuestionData,
  formatRatingQuestionData,
  formatStartingPageData,
} from './QuestionUtil/QuestionDataHandler';
import RankingQuestion from './RankingQuestion';
import RatingQuestion from './RatingQuestion';
import StartingPage from './StartingPage';

const choiceQuestionTypes = [
  QuestionTypes.SINGLE_CHOICE,
  QuestionTypes.MULTIPLE_CHOICE,
  QuestionTypes.CHOICE_PAGE,
];
const ratingQuestionTypes = [QuestionTypes.RATING, QuestionTypes.RATING_PAGE];

const QuestionWrapper = ({ data, onQuestionFormEdit }: any) => {
  const {
    questionnaire,
    questions,
    question,
    handleUpdateQuestion,
    isLoading,
  } = data;

  const methods = useForm<any>();
  const { formState } = methods;

  const [choices, setChoices] = useState<any>({});
  const [answers, setAnswers] = useState<any>({});
  const [selectedQuestionsList, setSelectedQuestionsList] = useState<any[]>([
    {},
  ]);

  const [previousQuestion, setPreviousQuestion] = useState<any>(
    question.questions?.[0]?.parentQuestion
  );
  const [feedbackQuestion, setFeedbackQuestion] = useState<any>(
    question.questions?.[0]?.followupQuestion
  );
  const [range, setRange] = useState<any>(
    question?.questions?.[0]?.parentQuestion?.type === QuestionTypes.RATING
      ? question.questions?.[0]?.conditions
      : [0, 1]
  );
  const [selectedChoices, setSelectedChoices] = useState<any>(
    question?.questions?.[0]?.parentQuestion?.type ===
      QuestionTypes.SINGLE_CHOICE ||
      question?.questions?.[0]?.parentQuestion?.type ===
        QuestionTypes.MULTIPLE_CHOICE
      ? question.questions?.[0]?.conditions
      : []
  );

  const [matrixQuestionChoice, setMatrixQuestionChoice] = useState<any>(
    question?.questions?.[0]?.parentQuestion?.type ===
      QuestionTypes.RATING_PAGE ||
      question?.questions?.[0]?.parentQuestion?.type ===
        QuestionTypes.CHOICE_PAGE
      ? question.questions?.[0]?.matrixQuestionChoice
      : null
  );

  useEffect(() => {
    onQuestionFormEdit(formState.isDirty);
  }, [formState, onQuestionFormEdit]);

  const getSelectedChoices = (question: any) => {
    let questionType: any = null;
    const conditions = question.questions?.[0]?.conditions;
    if (
      question?.questions?.[0]?.parentQuestion?.type ===
      QuestionTypes.CONDITIONAL
    ) {
      const parentConditionalQuestion =
        question?.questions?.[0]?.parentQuestion;
      questionType =
        parentConditionalQuestion?.question?.questions?.[0]?.followupQuestion
          .type;
    } else {
      questionType = question?.questions?.[0]?.parentQuestion?.type;
    }
    return choiceQuestionTypes.indexOf(questionType) !== -1 ? conditions : [];
  };

  const getSelectedRange = (question: any) => {
    let questionType: any = null;
    const conditions = question.questions?.[0]?.conditions;
    if (
      question?.questions?.[0]?.parentQuestion?.type ===
      QuestionTypes.CONDITIONAL
    ) {
      const parentConditionalQuestion =
        question?.questions?.[0]?.parentQuestion;
      questionType =
        parentConditionalQuestion?.question?.questions?.[0]?.followupQuestion
          .type;
    } else {
      questionType = question?.questions?.[0]?.parentQuestion?.type;
    }
    return ratingQuestionTypes.indexOf(questionType) !== -1
      ? conditions
      : [0, 1];
  };

  useEffect(() => {
    setPreviousQuestion(question.questions?.[0]?.parentQuestion || null);
    setFeedbackQuestion(question.questions?.[0]?.followupQuestion || null);

    const selectedRange = getSelectedRange(question);
    setRange(selectedRange);

    const selectedChoices = getSelectedChoices(question);
    setSelectedChoices(selectedChoices);

    setMatrixQuestionChoice(
      question?.questions?.[0]?.parentQuestion?.type ===
        QuestionTypes.RATING_PAGE ||
        question?.questions?.[0]?.parentQuestion?.type ===
          QuestionTypes.CHOICE_PAGE
        ? question.questions?.[0]?.matrixQuestionChoice
        : null
    );
  }, [question]);

  const onSubmit = (formData: any) => {
    const questionData = getQuestionsDataForForm(formData, question.type);
    handleUpdateQuestion(questionData);
  };

  const getQuestionsDataForForm = (formData: any, type: string) => {
    switch (type) {
      case QuestionTypes.LANDING:
        return formatStartingPageData(formData, data, type);
      case QuestionTypes.OPEN:
        return formatOpenQuestionData(formData, data, type);
      case QuestionTypes.RATING:
        return formatRatingQuestionData(formData, data, choices, type);
      case QuestionTypes.SINGLE_CHOICE:
        return formatChoicesQuestionData(formData, data, choices, type);
      case QuestionTypes.MULTIPLE_CHOICE:
        return formatChoicesQuestionData(formData, data, choices, type);
      case QuestionTypes.INFO:
        return formatInfoQuestionData(formData, data, type);
      case QuestionTypes.FINAL:
        return formatFinalQuestionData(formData, data, type);
      case QuestionTypes.CONDITIONAL:
        return formatConditionalQuestionData(formData, type);
      case QuestionTypes.CHOICE_PAGE:
        return formatMatrixChoiceQuestionData(
          formData,
          data,
          choices,
          answers,
          type
        );
      case QuestionTypes.RATING_PAGE:
        return formatMatrixRatingQuestionData(
          formData,
          data,
          choices,
          answers,
          type
        );
      case QuestionTypes.CUSTOM_LANDING:
        return formatCustomHTMLPageQuestionData(formData, data, type);
      case QuestionTypes.CUSTOM_FINAL:
        return formatCustomHTMLPageQuestionData(formData, data, type);
      case QuestionTypes.RANKING:
        return formatRankingQuestionData(formData, data, choices, type);
      case QuestionTypes.FORM:
        return formatFormQuestionData(
          formData,
          data,
          selectedQuestionsList,
          type
        );
    }
  };

  const getConditions = (question: any) => {
    const parentQuestion =
      question.type === QuestionTypes.CONDITIONAL
        ? question?.question?.questions?.[0]?.followupQuestion
        : question;
    return parentQuestion.type === QuestionTypes.RATING ||
      parentQuestion.type === QuestionTypes.RATING_PAGE
      ? range
      : selectedChoices;
  };

  const formatConditionalQuestionData = (formData: any, type?: string) => {
    const followupQuestionData: any = getQuestionsDataForForm(
      formData,
      feedbackQuestion.type
    );

    const questionData: any = {
      parentQuestion: previousQuestion,
      conditions: getConditions(previousQuestion),
      followupQuestion: { ...followupQuestionData?.variables },
    };

    if (
      previousQuestion.type === QuestionTypes.RATING_PAGE ||
      previousQuestion.type === QuestionTypes.CHOICE_PAGE
    ) {
      questionData.matrixQuestionChoice = matrixQuestionChoice;
    }

    return {
      variables: {
        questionId: question._id,
        label: formData[questionnaire.supportedLanguages[0].code].label,
        class: question.class,
        type: type || question.type,
        followupParentId: previousQuestion.value,
        questions: [questionData],
        attributes: {
          optional: formData.optional || false,
        },
      },
    };
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className='question-wrapper-form'
        >
          <div className='question-wrapper'>
            <div className='q-content'>
              <div>
                <div className='inner-tabs'>
                  {question?.type === QuestionTypes.LANDING && (
                    <StartingPage
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.CUSTOM_LANDING && (
                    <CustomStartingPage
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.OPEN && (
                    <OpenQuestion
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.RATING && (
                    <RatingQuestion
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                        choices,
                        setChoices,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.SINGLE_CHOICE && (
                    <ChoicesQuestion
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                        singleChoice: true,
                        choices,
                        setChoices,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.MULTIPLE_CHOICE && (
                    <ChoicesQuestion
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                        singleChoice: false,
                        choices,
                        setChoices,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.INFO && (
                    <InfoPage
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.CONDITIONAL && (
                    <ConditionalQuestion
                      data={{
                        questionnaire,
                        question,
                        questions,
                        settings: false,
                        isLoading,
                        choices,
                        setChoices,
                        previousQuestion,
                        setPreviousQuestion,
                        feedbackQuestion,
                        setFeedbackQuestion,
                        range,
                        setRange,
                        selectedChoices,
                        setSelectedChoices,
                        answers,
                        setAnswers,
                        matrixQuestionChoice,
                        setMatrixQuestionChoice,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.FINAL && (
                    <FinalPage
                      data={{
                        questionnaire,
                        question,
                        questions,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.CUSTOM_FINAL && (
                    <CustomFinalPage
                      data={{
                        questionnaire,
                        question,
                        questions,
                        settings: false,
                        isLoading,
                      }}
                    />
                  )}
                  {(question?.type === QuestionTypes.RATING_PAGE ||
                    question?.type === QuestionTypes.CHOICE_PAGE) && (
                    <MatrixQuestion
                      data={{
                        questionnaire,
                        question,
                        questions,
                        settings: false,
                        isLoading,
                        choices,
                        setChoices,
                        answers,
                        setAnswers,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.RANKING && (
                    <RankingQuestion
                      data={{
                        questionnaire,
                        question,
                        settings: false,
                        choices,
                        setChoices,
                      }}
                    />
                  )}
                  {question?.type === QuestionTypes.FORM && (
                    <FormQuestion
                      data={{
                        questionnaire,
                        question,
                        questions,
                        selectedQuestionsList,
                        setSelectedQuestionsList,
                        settings: false,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='divider'></div>
            <div className='q-settings'>
              <span className='header-title'>Settings</span>
              {question?.type === QuestionTypes.LANDING && (
                <StartingPage
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                  }}
                />
              )}
              {question?.type === QuestionTypes.CUSTOM_LANDING && (
                <CustomStartingPage
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                  }}
                />
              )}
              {question?.type === QuestionTypes.OPEN && (
                <OpenQuestion
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                  }}
                />
              )}
              {question?.type === QuestionTypes.RATING && (
                <RatingQuestion
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                    choices,
                    setChoices,
                  }}
                />
              )}
              {question?.type === QuestionTypes.SINGLE_CHOICE && (
                <ChoicesQuestion
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                    singleChoice: true,
                    choices,
                    setChoices,
                  }}
                />
              )}
              {question?.type === QuestionTypes.MULTIPLE_CHOICE && (
                <ChoicesQuestion
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                    singleChoice: false,
                    choices,
                    setChoices,
                  }}
                />
              )}
              {question?.type === QuestionTypes.INFO && (
                <InfoPage
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                  }}
                />
              )}
              {question?.type === QuestionTypes.CONDITIONAL && (
                <ConditionalQuestion
                  data={{
                    questionnaire,
                    question,
                    questions,
                    settings: true,
                    choices,
                    setChoices,
                  }}
                />
              )}
              {question?.type === QuestionTypes.FINAL && (
                <FinalPage
                  data={{
                    questionnaire,
                    question,
                    questions,
                    settings: true,
                  }}
                />
              )}
              {question?.type === QuestionTypes.CUSTOM_FINAL && (
                <CustomFinalPage
                  data={{
                    questionnaire,
                    question,
                    questions,
                    settings: true,
                  }}
                />
              )}
              {(question?.type === QuestionTypes.RATING_PAGE ||
                question?.type === QuestionTypes.CHOICE_PAGE) && (
                <MatrixQuestion
                  data={{
                    questionnaire,
                    question,
                    questions,
                    settings: true,
                    isLoading,
                    choices,
                    setChoices,
                    answers,
                    setAnswers,
                  }}
                />
              )}
              {question?.type === QuestionTypes.RANKING && (
                <RankingQuestion
                  data={{
                    questionnaire,
                    question,
                    settings: true,
                    choices,
                    setChoices,
                  }}
                />
              )}
              {question?.type === QuestionTypes.FORM && (
                <FormQuestion
                  data={{
                    questionnaire,
                    question,
                    questions,
                    selectedQuestionsList,
                    setSelectedQuestionsList,
                    settings: true,
                  }}
                />
              )}
              <div className='btn-pre-wrapper'>
                <AccessControl allowedPermissions={['update:question']}>
                  <button
                    type='submit'
                    className='save-btn'
                    disabled={isLoading}
                  >
                    Save
                  </button>
                </AccessControl>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default QuestionWrapper;
