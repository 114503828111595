import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import { customModal } from '../../../Constants/ThemeO2T';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { getOneLanguageObject } from 'src/Utils/languages';

const animatedComponents = makeAnimated();

const AddNewLanguageModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalData,
  onSubmit,
  error,
  isLoading,
}) => {
  const {
    register,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm<any>();
  const closeModal = () => {
    toggleModal();
  };
  const clearForm = () => {
    reset({
      name: '',
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { language, googleTranslate } = getValues();
    const languageObj = getOneLanguageObject(language.value);

    const variables = {
      language: languageObj,
      useGoogleTranslate: googleTranslate,
      questionnaireId: modalData.questionnaire._id,
    };

    onSubmit({ variables });
    clearForm();
  };

  useEffect(() => {
    reset({
      name: modalData?.selectedProduct?.name,
    });
  }, [modalData, reset]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Add Language Modal'
        style={{
          content: {
            ...customModal.content,
            width: '35%',
            height: 'fit-content',
            maxHeight: '90%',
            overflow: 'hidden',
          },
        }}
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>
              Pick your extra language
            </h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <div className='language-add-edit'>
                  <div>
                    <div className='design-content-action-info'>
                      <div className='language-input-field'>
                        <div>Extra languages:</div>
                        <div className='form-field-full-length'>
                          <Controller
                            control={control}
                            name='language'
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => (
                              <Select
                                isMulti={false}
                                options={modalData.languages}
                                value={value}
                                onChange={onChange}
                                components={animatedComponents}
                              />
                            )}
                          />
                          {errors.language && (
                            <span className='error-msg'>
                              Language is required
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='language-input-field'>
                        <div>Use Google translate:</div>
                        <div className='form-field-checkbox'>
                          <input
                            type='checkbox'
                            defaultChecked={true}
                            {...register('googleTranslate')}
                          />
                          {errors.language && (
                            <span className='error-msg'>
                              Language is required
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='alert-wrapper'>
                  {error && <p>{error.message}</p>}
                </div>

                <div
                  className='o2t-modal__footer'
                  style={{ paddingTop: '1rem' }}
                >
                  <button
                    onClick={closeModal}
                    className='link-button mr-2'
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    data-testid='product-save-btn'
                    disabled={isLoading}
                  >
                    Add Language
                    <span>
                      <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
      <ToastContainer position='bottom-right' theme='colored' />
    </>
  );
};

export default AddNewLanguageModal;
