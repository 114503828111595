import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';

import { IPage } from '../Interfaces';
import Loading from '../Components/Basic/Loading';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import {
  GET_PAGINATION_USERS,
  GET_USER,
  GET_USERS_BY_EMAIL,
  MERGE_USER,
  UPDATE_USER,
  UPDATE_USER_PASSWORD,
} from '../Query';
import { useLazyQuery, useMutation } from '@apollo/client';
import { setLoggedInUserToStore } from '../States/Actions/usersAction';
import jwtDecode from 'jwt-decode';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import { customTable } from 'src/Constants/ThemeO2T';
import AccountLinkConfirmationModal from 'src/Components/SettingsModule/AccountLinkConfirmationModal';
import Feature from 'src/Components/Feature';

const tabRoutes = ['#profile', '#password', '#accounts'];

const Dashboard: React.FunctionComponent<IPage> = (props: any) => {
  const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE || '';

  const { user } = props;
  const { getAccessTokenSilently } = useAuth0();
  const [accessToken, setAccessToken] = useState('');
  const [activeTab, setActiveTab] = useState<number>(1);
  const [linkedAccounts, setLinkedAccounts] = useState<any[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const toggleOpenConfirmationModal = () => {
    setOpenConfirmationModal(!openConfirmationModal);
  };

  const [loadUsersWithSameEmail, { loading }] = useLazyQuery(
    GET_USERS_BY_EMAIL,
    {
      errorPolicy: 'ignore',
      onCompleted: async (data: any) => {
        const linkedAccounts: any[] = [];

        data.qmsUserByEmail.forEach((qmsUser: any) => {
          if (qmsUser.identities.length === 1) {
            if (qmsUser.user_id !== user.user_id) linkedAccounts.push(qmsUser);
          } else {
            qmsUser.identities.forEach((identity: any) => {
              if (!user.user_id.includes(identity.user_id))
                linkedAccounts.push({ ...qmsUser, identities: [identity] });
            });
          }
        });
        setLinkedAccounts(linkedAccounts);
      },
    }
  );

  const handleRouteChange = useCallback(
    (route: string) => {
      const index = tabRoutes.indexOf(route);
      if (index === 2) {
        if (user?.email && user?.user_id) {
          loadUsersWithSameEmail({
            variables: { email: user.email, sub: user.user_id },
          });
        }
      }
      setActiveTab(index);
    },
    [loadUsersWithSameEmail, user]
  );

  useEffect(() => {
    handleRouteChange(location?.hash);
  }, [location, handleRouteChange]);

  const {
    register: registerPasswordForm,
    handleSubmit: handlePasswordFormSubmit,
    formState: { errors: passwordFormErrors },
    reset: resetPasswordForm,
  } = useForm();

  const updateUserInStore = async (data: any, options: any) => {
    const config = options.isNewToken ? { ignoreCache: true } : {};
    const token = await getAccessTokenSilently(config);
    const decoded = jwtDecode<any>(token);
    const roles = decoded[auth0Audience + '_roles'];
    const user = { ...data.qmsuser };

    user.roles = roles;
    user.permissions = decoded.permissions;
    props.setLoggedInUserToStore(user);
  };

  const [loadUser] = useLazyQuery(GET_USER, {
    errorPolicy: 'ignore',
  });

  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {
        query: GET_PAGINATION_USERS,
        variables: { page: 0, per_page: 10, sort: 'created_at:-1' },
      },
    ],
    errorPolicy: 'none',
    onCompleted: (completedData: any) => {
      loadUser({
        variables: { userId: completedData.updateQmsuser.user_id },
      }).then((data: any) => updateUserInStore(data, { isNewToken: false }));
      resetPasswordForm();
      toast.success('User updated successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [mergeUser] = useMutation(MERGE_USER, {
    errorPolicy: 'none',
    onCompleted: async (completedData: any) => {
      loadUser({
        variables: { userId: user.user_id },
      }).then((data: any) => updateUserInStore(data, { isNewToken: true }));
      toast.success('User accounts merged successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD, {
    refetchQueries: [
      {
        query: GET_PAGINATION_USERS,
        variables: { page: 0, per_page: 10, sort: 'created_at:-1' },
      },
    ],
    errorPolicy: 'none',
    onCompleted: (completedData: any) => {
      loadUser({
        variables: { userId: completedData.changePassword.user_id },
      }).then((data: any) => updateUserInStore(data, { isNewToken: false }));
      resetPasswordForm();
      toast.success('User updated successfully');
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  const onSubmit = (formData: any) => {
    updateUser({
      variables: {
        user_id: user.user_id,
        name: formData.name,
        nickname: formData.nickname,
        user_metadata: {
          use_mfa: formData.mfa,
        },
      },
    });
  };

  useEffect(() => {
    getAccessTokenSilently().then((token) => {
      setAccessToken(token);
    });
  }, [getAccessTokenSilently]);

  const onPasswordUpdate = (formData: any) => {
    if (formData.newPassword === formData.confirmPassword) {
      updateUserPassword({
        variables: {
          user_id: user.user_id,
          password: formData.newPassword,
        },
      });
    } else {
      toast.error('Passwords do not match');
    }
  };

  const handleTabChange = (tabIndex: number) => {
    const hash = tabRoutes[tabIndex];
    history.push(`/profile${hash}`);
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
    },
    {
      name: 'E-mail',
      selector: (row: any) => row.email,
    },
    {
      name: 'Identity provider',
      selector: (row: any) => row.identities?.[0]?.provider,
    },
    {
      name: 'Database',
      selector: (row: any) => {
        return `${row.identities
          .map((identity: any) => ` ${identity.connection}`)
          .toString()}`;
      },
    },
    {
      button: true,
      cell: (row: any) => (
        <div className='actions-col' id={row._id}>
          {row.identities?.[0]?.provider +
            '|' +
            row.identities?.[0]?.user_id ===
            row.user_id && (
            <button
              className='btn'
              onClick={(event) => {
                event.preventDefault();
                setSelectedAccount(row);
                toggleOpenConfirmationModal();
              }}
            >
              Link
            </button>
          )}
        </div>
      ),
    },
  ];

  const onAccountLink = (selectedUser: any) => {
    toggleOpenConfirmationModal();
    let params = null;
    if (selectedUser && user) {
      params = {
        variables: {
          primaryUserIdentity: {
            ...user.identities?.[0],
            id: user.user_id,
          },
          secondaryUserIdentity: {
            ...selectedUser.identities?.find(
              (identity: any) => identity.provider === 'auth0'
            ),
            id: selectedUser.user_id,
          },
        },
      };
    } else {
      return;
    }

    mergeUser(params);
  };

  return (
    <div className='profile-screen'>
      <div className='profile-container'>
        <Tabs
          selectedIndex={activeTab}
          onSelect={(index) => handleTabChange(index)}
        >
          <TabList>
            <Tab>Profile</Tab>
            <Tab>Change Password</Tab>
            <Tab>Link Accounts</Tab>
          </TabList>

          <TabPanel>
            <div className='bg-wrapper'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <section>
                  <div className='form-wrapper'>
                    <div className='row'>
                      <div className='form-group col'>
                        <label>Name</label>
                        <input
                          className='form-field'
                          type='text'
                          {...register('name', {
                            required: true,
                            value: user?.name,
                          })}
                        />
                        {errors.name && (
                          <span className='error-msg'>Name is required.</span>
                        )}
                      </div>
                    </div>
                    <div className='row'>
                      <div className='form-group col'>
                        <label>Email</label>
                        <input
                          className='form-field'
                          type='text'
                          value={user?.email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className='row'>
                      <div className='form-group col'>
                        <label>Nickname</label>
                        <input
                          className='form-field'
                          type='text'
                          {...register('nickname', {
                            value: user?.nickname,
                          })}
                        />
                      </div>
                    </div>
                    <div className='form-group checkbox-field'>
                      <label className='full-width-label'>Enable MFA</label>
                      <input
                        className='checkbox'
                        type='checkbox'
                        {...register('mfa', {
                          value: user?.user_metadata?.use_mfa,
                        })}
                      />
                    </div>
                    <div className='row form-field'>
                      <div className='form-group'>
                        <button className='submit' type='submit'>
                          Update
                        </button>
                      </div>
                    </div>
                    <Feature name='displayUserAccessToken'>
                      <div className='row'>
                        <div className='form-group col'>
                          <label>Access token</label>
                          <textarea readOnly rows={6} value={accessToken} />
                        </div>
                      </div>
                    </Feature>
                  </div>
                </section>
              </form>
            </div>
          </TabPanel>
          <TabPanel>
            <div className='bg-wrapper'>
              <div className='alert-wrapper'>
                {(user?.identities?.[0]?.connection === 'One2ten' ||
                  user?.identities?.[0]?.isSocial) && (
                  <p>
                    Users signing in with social or enterprise connections must
                    reset their passwords with the identity provider
                  </p>
                )}
              </div>
              {!(
                user.identities?.[0]?.connection === 'One2ten' ||
                user.identities?.[0]?.isSocial
              ) ? (
                <form onSubmit={handlePasswordFormSubmit(onPasswordUpdate)}>
                  <section>
                    <div className='form-wrapper'>
                      <div className='row info-container'>
                        <h3>Password Policy</h3>
                        <p>
                          The password must contain at least one special
                          character, lower case, upper case letters and numbers.
                          Must have at least 8 characters in length.
                        </p>
                      </div>
                      <div className='row'>
                        <div className='form-group col'>
                          <label>
                            New password<span className='mandatory'>*</span>
                          </label>
                          <input
                            className='form-field'
                            type='password'
                            {...registerPasswordForm('newPassword', {
                              required: true,
                              disabled:
                                user.identities?.[0]?.connection ===
                                  'One2ten' || user.identities?.[0]?.isSocial,
                            })}
                          />
                          {passwordFormErrors.newPassword && (
                            <span className='error-msg'>
                              New password is required.
                            </span>
                          )}
                        </div>
                      </div>
                      <div className='row'>
                        <div className='form-group col'>
                          <label>
                            Confirm password<span className='mandatory'>*</span>
                          </label>
                          <input
                            className='form-field'
                            type='password'
                            {...registerPasswordForm('confirmPassword', {
                              required: true,
                              disabled:
                                user.identities?.[0]?.connection ===
                                  'One2ten' || user.identities?.[0]?.isSocial,
                            })}
                          />
                          {passwordFormErrors.confirmPassword && (
                            <span className='error-msg'>
                              Confirm password field is required.
                            </span>
                          )}
                        </div>
                      </div>

                      <div className='row form-field'>
                        <div className='form-group'>
                          <button
                            type='submit'
                            disabled={
                              user.identities?.[0]?.connection === 'One2ten' ||
                              user.identities?.[0]?.isSocial
                            }
                          >
                            {' '}
                            Update Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              ) : (
                <div className='container'></div>
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <div className='bg-wrapper'>
              <section>
                <div className='form-wrapper__accounts'>
                  <div className='row info-container'>
                    <p>Link your account with QMS to receive permissions.</p>
                  </div>
                  <div className='row'>
                    <div className='form-group col'>
                      <DataTable
                        columns={columns}
                        data={linkedAccounts}
                        theme='o2t-table'
                        customStyles={customTable}
                        progressPending={loading}
                        progressComponent={<Loading />}
                        pagination={true}
                        paginationServer={true}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </TabPanel>
        </Tabs>
        <ToastContainer position='bottom-right' theme='colored' />
      </div>
      {openConfirmationModal && (
        <AccountLinkConfirmationModal
          modalIsOpen={openConfirmationModal}
          toggleModal={toggleOpenConfirmationModal}
          modalTitle={'Link Account'}
          selectedAccount={selectedAccount}
          onAccountLinkClick={onAccountLink}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return { user: state.user };
};

export default connect(mapStateToProps, { setLoggedInUserToStore })(
  withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <Loading />,
  })
);
