import React from 'react';
import Header from './Layout/Header';
import LoginButton from './Basic/LoginButton';
import o2tLogo from '../assets/signin-person.png';

const LoginScreen = () => {
  return (
    <>
      <Header />
      <div>
        <div className='login-container'>
          <div className='login-txt'>
            <div className='login-img-container '>
              <div className='login-img-circle'>
                <div className='login-img'>
                  <img src={o2tLogo} alt='Brand Logo' width='350' />
                </div>
              </div>
            </div>
            <div className='login-action-container'>
              <div className='btn-wrapper'>
                <LoginButton />
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    window.open('https://one2ten.com/contact/', '_blank');
                  }}
                >
                  CONTACT
                </button>
                ;
              </div>
            </div>
          </div>
          <div className='bg-bubble-container'>
            <div id='full-circle-one' className='circle__fill'></div>
            <div id='full-circle-two' className='circle__fill'></div>
            <div id='outer-circle-one' className='circle__border'></div>
            <div id='outer-circle-two' className='circle__border'></div>
            <div id='outer-circle-three' className='circle__border'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginScreen;
