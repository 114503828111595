import { faPlayCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';
import ReactHtmlParser from 'react-html-parser';
import QuestionTranslationWrapper from './QuestionTranslateModule/QuestionTranslationWrapper';
import AddNewLanguageModal from './QuestionTranslateModule/AddNewLanguageModal';
import languageData from '../../Utils/meta/languages.json';
import { getLanguageOptions } from 'src/Utils/languages';
import { useMutation } from '@apollo/client';
import { ADD_QUESTIONNAIRE_LANGUAGE } from 'src/Query/questionnaires.query';
import { toast } from 'react-toastify';
import { setQuestionnaireToStore } from 'src/States/Actions/questionnaireAction';

const QuestionnaireTranslations = (props: any) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [questions, setQuestions] = useState<any[]>(props.questions || []);
  const [loadQuestions, setLoadQuestions] = useState(props.loadQuestions);

  const languages = getLanguageOptions(languageData);

  useEffect(() => {
    setQuestions(props.questions || []);
    setLoadQuestions(props.loadQuestions);
  }, [props]);

  const handleScroll = (event: any) => {
    props.onScroll();
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const checkPermissions = (
    permissions: string[],
    allowedPermissions: string[]
  ) => {
    return (
      permissions.filter(
        (permission: string) => allowedPermissions.indexOf(permission) !== -1
      ).length > 0
    );
  };

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      questions,
      result.source.index,
      result.destination.index
    );

    setQuestions(newOrder);
    props.onDragEnd({
      variables: {
        questions: newOrder.map((question: any, index: number) => {
          return {
            _id: question._id,
            order: index + 1,
          };
        }),
      },
    });
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onOpenAddLanguageModel = (event: any) => {
    event.preventDefault();
    setModalIsOpen(true);
  };

  const [
    addLanguage,
    { error: addLanguageError, loading: addLanguageLoading },
  ] = useMutation(ADD_QUESTIONNAIRE_LANGUAGE, {
    errorPolicy: 'none',
    onCompleted: (data) => {
      props.setQuestionnaireToStore(data.addLanguage);
      toast.success(
        'Language added successfully. Translations are running in the background, please refresh the page after a few seconds!'
      );
      setModalIsOpen(false);
    },
    onError: (error) => {
      console.error(error);
      toast.error('Failed to add the language');
    },
  });

  const handleAddNewLanguage = (data: any) => {
    addLanguage(data);
  };

  return (
    <div className='questions-screen'>
      <div className='d-flex'>
        <div className='q-list'>
          <div className='sub-title'>
            <span onClick={() => props.setActiveTab(1)}>Back to overview</span>
          </div>
          <ul onScroll={handleScroll}>
            <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
              <Droppable
                isDropDisabled={
                  !checkPermissions(props.userPermissions, [
                    'reorder:questions',
                  ])
                }
                droppableId='droppable'
              >
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {loadQuestions ? (
                      <AccessControl
                        allowedPermissions={['list:questions']}
                        renderNoAccess={() => (
                          <Alert
                            message={
                              'Unauthorized. You do not have permission to view questions'
                            }
                          />
                        )}
                      >
                        {questions.map((question: any, index: number) => {
                          return (
                            <Draggable
                              draggableId={question._id}
                              index={index}
                              key={question._id}
                            >
                              {(provided) => (
                                <AccessControl
                                  allowedPermissions={['view:question']}
                                  renderNoAccess={() => {
                                    return (
                                      <li
                                        key={question._id}
                                        className='disabled'
                                      >
                                        <div className='q-info'>
                                          <div className='q-info__number'>
                                            <span>{index + 1}</span>
                                          </div>
                                          <div className='q-info__question'>
                                            <div className='q-info__question--title'>
                                              {question.label}
                                            </div>
                                            <div className='q-info__question--type'>
                                              <FontAwesomeIcon
                                                className='mr-2'
                                                icon={faPlayCircle}
                                              />
                                              {question.type.replaceAll(
                                                '_',
                                                ' '
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  }}
                                >
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                    onClick={() =>
                                      props.handleQuestionSelect(question)
                                    }
                                    key={question._id}
                                    className={
                                      props.selectedQuestion?.pageId ===
                                      question.pageId
                                        ? 'q-active'
                                        : ''
                                    }
                                  >
                                    <div className='q-info'>
                                      <div className='q-info__number'>
                                        <span>{index + 1}</span>
                                      </div>
                                      <div className='q-info__question'>
                                        <div className='q-info__question--title'>
                                          {ReactHtmlParser(question.label)}
                                        </div>
                                        <div className='q-info__question--type'>
                                          <FontAwesomeIcon
                                            className='mr-2'
                                            icon={faPlayCircle}
                                          />
                                          {question.type.replaceAll('_', ' ')}
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </AccessControl>
                              )}
                            </Draggable>
                          );
                        })}
                      </AccessControl>
                    ) : (
                      <Fragment>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                        <li>
                          <QuestionSkeleton />
                        </li>
                      </Fragment>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ul>
        </div>
        {props.selectedQuestion ? (
          <QuestionTranslationWrapper
            data={{
              questionnaire: props.questionnaire,
              question: props.selectedQuestion,
              questions,
              handleUpdateQuestion: props.handleUpdateQuestion,
              isLoading: props.isLoading,
            }}
            onOpenAddLanguageModel={onOpenAddLanguageModel}
            onQuestionFormEdit={props.onQuestionFormEdit}
          />
        ) : (
          <Fragment>
            <div className='question-wrapper__translate'>
              <div className='add-new'>
                <span>Add new languages</span>

                <button onClick={onOpenAddLanguageModel}>+</button>
              </div>
              <div>
                <div className='q-content'></div>
                <div className='q-settings'></div>
              </div>
            </div>
          </Fragment>
        )}
        {modalIsOpen && (
          <AddNewLanguageModal
            modalIsOpen={modalIsOpen}
            toggleModal={toggleModal}
            modalData={{ languages, questionnaire: props.questionnaire }}
            isLoading={addLanguageLoading}
            error={addLanguageError}
            onSubmit={handleAddNewLanguage}
          />
        )}
      </div>
    </div>
  );
};

const QuestionSkeleton = () => {
  return (
    <ContentLoader viewBox='0 0 380 40'>
      <rect x='0' y='0' rx='5' ry='5' width='70' height='70' />
      <rect x='80' y='17' rx='4' ry='4' width='300' height='13' />
      <rect x='80' y='40' rx='3' ry='3' width='250' height='10' />
    </ContentLoader>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userPermissions: state.user?.permissions || [],
  };
};

export default connect(mapStateToProps, { setQuestionnaireToStore })(
  QuestionnaireTranslations
);
