import { gql } from '@apollo/client';

export const GET_PAGINATED_QUESTIONNAIRES = gql`
  query GetQuestionnaires(
    $licenseId: ID
    $productId: ID
    $isProductItem: Boolean
    $searchTerm: String
    $sortBy: QuestionnaireSortingOption
    $sortOrder: SortOrder
    $status: QuestionnaireStatusType
    $isArchived: Boolean
    $limit: Int!
    $next: String
    $previous: String
  ) {
    questionnaires(
      licenseId: $licenseId
      productId: $productId
      isProductItem: $isProductItem
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      status: $status
      isArchived: $isArchived
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        name
        status
        activeFrom
        activeTill
        questionCount
        defaultLanguage
        supportedLanguages
        styles
        isArchived
        isProductItem
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_QUESTIONNAIRE = gql`
  mutation AddQuestionnaire(
    $licenseId: ID
    $productId: ID
    $name: String!
    $activeFrom: String
    $activeTill: String
    $defaultLanguage: JSONObject
    $supportedLanguages: [JSONObject]
    $styles: JSONObject
    $isProductItem: Boolean
  ) {
    addQuestionnaire(
      licenseId: $licenseId
      productId: $productId
      name: $name
      activeFrom: $activeFrom
      activeTill: $activeTill
      defaultLanguage: $defaultLanguage
      supportedLanguages: $supportedLanguages
      styles: $styles
      isProductItem: $isProductItem
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      activeFrom
      activeTill
      defaultLanguage
      supportedLanguages
      styles
      isProductItem
    }
  }
`;

export const ARCHIVE_QUESTIONNAIRE = gql`
  mutation ArchiveQuestionnaire($_id: ID!) {
    archiveQuestionnaire(_id: $_id)
  }
`;

export const EDIT_QUESTIONNAIRE = gql`
  mutation UpdateQuestionnaire(
    $_id: ID!
    $name: String!
    $activeFrom: String
    $activeTill: String
    $defaultLanguage: JSONObject
    $supportedLanguages: [JSONObject]
  ) {
    updateQuestionnaire(
      _id: $_id
      name: $name
      activeFrom: $activeFrom
      activeTill: $activeTill
      defaultLanguage: $defaultLanguage
      supportedLanguages: $supportedLanguages
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      activeFrom
      activeTill
      defaultLanguage
      supportedLanguages
      isPreviewed
    }
  }
`;

export const QUESTIONNAIRE = gql`
  query GetQuestionnaire($questionnaireId: ID!) {
    questionnaire(_id: $questionnaireId) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      licenseId
      name
      status
      activeFrom
      activeTill
      defaultLanguage
      questionnaireType
      supportedLanguages
      version
      testLink
      testLinkUUID
      shareableLink
      styles
      questionCount
      isPreviewed
      isProductItem
      productId
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_STYLES = gql`
  mutation SetQuestionnaireStyles(
    $questionnaireId: ID!
    $backgroundColor: String
    $titleColor: String
    $subTextColor: String
    $buttonColor: String
    $buttonArrowColor: String
    $buttonBorderColor: String
    $logo: String
    $backgroundImage: String
    $customCSS: String
    $styleTemplateType: String
    $styleTemplateId: ID
    $name: String
  ) {
    setQuestionnaireStyles(
      _id: $questionnaireId
      backgroundColor: $backgroundColor
      titleColor: $titleColor
      subTextColor: $subTextColor
      buttonColor: $buttonColor
      buttonArrowColor: $buttonArrowColor
      buttonBorderColor: $buttonBorderColor
      logo: $logo
      backgroundImage: $backgroundImage
      customCSS: $customCSS
      styleTemplateType: $styleTemplateType
      styleTemplateId: $styleTemplateId
      name: $name
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      licenseId
      name
      status
      activeFrom
      activeTill
      defaultLanguage
      questionnaireType
      supportedLanguages
      version
      testLink
      shareableLink
      styles
      isArchivedLicense
      isPreviewed
    }
  }
`;

export const PREVIEW_QUESTIONNAIRE = gql`
  mutation PreviewQuestionnaire($questionnaireId: ID!) {
    previewQuestionnaire(_id: $questionnaireId) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      licenseId
      name
      status
      activeFrom
      activeTill
      defaultLanguage
      questionnaireType
      supportedLanguages
      version
      testLink
      shareableLink
      styles
      isArchivedLicense
      isPreviewed
    }
  }
`;

export const GET_SIGNED_URL = gql`
  query GetSignedURL($licenseId: ID, $key: String!) {
    signedURL(licenseId: $licenseId, key: $key) {
      key
      url
    }
  }
`;

export const ADD_QUESTIONNAIRE_LANGUAGE = gql`
  mutation AddLanguage(
    $questionnaireId: ID!
    $useGoogleTranslate: Boolean!
    $language: JSONObject!
  ) {
    addLanguage(
      questionnaireId: $questionnaireId
      useGoogleTranslate: $useGoogleTranslate
      language: $language
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      licenseId
      name
      status
      activeFrom
      activeTill
      defaultLanguage
      questionnaireType
      supportedLanguages
      version
      testLink
      shareableLink
      styles
      isArchivedLicense
      isPreviewed
      productId
      useGoogleTranslate
    }
  }
`;

export const UPDATE_QUESTIONNAIRE_VERSION = gql`
  mutation UpdateQuestionnaireVersion($questionnaireId: ID!) {
    updateQuestionnaireVersion(_id: $questionnaireId) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      licenseId
      name
      status
      activeFrom
      activeTill
      defaultLanguage
      questionnaireType
      supportedLanguages
      version
      testLink
      shareableLink
      styles
      isArchivedLicense
      isPreviewed
    }
  }
`;
