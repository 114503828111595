import IRoute from '../Interfaces/route';
import Questionnaires from '../Pages/Questionnaires';
import Home from '../Pages/Home';
import Users from '../Pages/Users';
import NotFound from '../Pages/NotFound';
import Licenses from '../Pages/Licenses';
import Products from '../Pages/Products';
import LinkDataSets from '../Pages/LinkDataSets';
import AddEditLicense from '../Components/LicenseModule/AddEditLicense';
import QuestionsTabs from '../Components/QuestionnaireModule/QuestionsTabs';
import Profile from '../Pages/Profile';
import Templates from 'src/Pages/StyleTemplates';
import SmartAnalytics from 'src/Pages/SmartAnalytics';

const Routes: IRoute[] = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    exact: true,
  },
  {
    path: '/questionnaires',
    name: 'Questionnaires',
    component: Questionnaires,
    exact: true,
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    exact: true,
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: Licenses,
    exact: true,
  },
  {
    path: '/licenses/add',
    name: 'Add License',
    component: AddEditLicense,
    exact: true,
  },
  {
    path: '/licenses/view/:licenseId',
    name: 'Edit License',
    component: AddEditLicense,
    exact: true,
  },
  {
    path: '/link-data-sets',
    name: 'Link Data Sets',
    component: LinkDataSets,
    exact: true,
  },
  {
    path: '/link-data-sets/view/:id',
    name: 'Edit link Data',
    component: LinkDataSets,
    exact: true,
  },
  {
    path: '/questionnaires/:questionnaireId/questions',
    name: 'Questions',
    component: QuestionsTabs,
    exact: true,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    exact: true,
  },
  {
    path: '/templates',
    name: 'Templates',
    component: Templates,
    exact: true,
  },
  {
    path: '/products',
    name: 'Products',
    component: Products,
    exact: true,
  },
  {
    path: '/products/questionnaires',
    name: 'Product Questionnaires',
    component: Questionnaires,
    exact: true,
  },
  {
    path: '/products/questionnaires/:questionnaireId/questions',
    name: 'Questions',
    component: QuestionsTabs,
    exact: true,
  },
  {
    path: '/products/templates',
    name: 'Templates',
    component: Templates,
    exact: true,
  },
  {
    path: '/analytics',
    name: 'Smart Analytics',
    component: SmartAnalytics,
    exact: true,
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
    exact: true,
  },
];

export default Routes;
