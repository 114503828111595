import React, { useState, useEffect, Fragment } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';

import { customModal } from '../../Constants/ThemeO2T';
import { QuestionnaireStatus } from 'src/Utils/types';

export const UpdateQuestionnaireVersionModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  error,
  modalData,
  onSubmit,
  isLoading,
  isUnpublish,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdateQuestionnaire = () => {
    onSubmit({
      variables: { questionnaireId: modalData._id },
    });
  };

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customModal}
        contentLabel='Update Version Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            {modalData.status === QuestionnaireStatus.CONCEPT ? (
              <Fragment>
                <p>Are you sure you want to publish the questionnaire?</p>
                <p>
                  Publishing questionnaire version will update the questionnaire
                  status, version number and create a new version record
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  Are you sure you want to update the questionnaire version?
                </p>
                <p>
                  Updating questionnaire version will update the version number
                  and create new version record
                </p>
              </Fragment>
            )}
          </div>
          <div className='alert-wrapper'>{error && <p>{error.message}</p>}</div>
          <div className='o2t-modal__footer'>
            <button
              onClick={toggleModal}
              className='link-button mr-2'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button onClick={handleUpdateQuestionnaire} disabled={isLoading}>
              Update
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer position='bottom-right' theme='colored' />
    </>
  );
};
