import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useRouteMatch } from 'react-router-dom';

import { IPage } from '../Interfaces';
import LicenseGrid from '../Components/LicenseModule/LicenseGrid';
import { NavLink } from 'react-router-dom';
import {
  setLicenseDataToStore,
  displayToastMessage,
} from '../States/Actions/licenseAction';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from '../Components/Basic/Loading';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import {
  DELETE_LICENSE,
  GET_PAGINATED_LICENSES,
} from '../Query/licenses.query';
import { useMutation, useQuery } from '@apollo/client';
import { DeleteLicenseModal } from '../Components/LicenseModule/DeleteLicenseModal';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';

const Licenses: React.FunctionComponent<IPage> = ({
  setLicenseDataToStore,
  licenseToast,
  licenses,
  total,
  next,
  previous,
  displayToastMessage,
  searchData,
}: any) => {
  const { url } = useRouteMatch();

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLicense, setDeleteLicense] = useState(null);

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const handleDeleteLicenseSelect = (license: any) => {
    setDeleteLicense(license);
    toggleDeleteModal();
  };

  const { loading, error } = useQuery(GET_PAGINATED_LICENSES, {
    variables: { ...paginationDetails, searchTerm: searchData.searchTerm },
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setLicenseDataToStore(completedData.licenses);
      }
    },
  });

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
      };
    });
  }, [searchData]);

  useEffect(() => {
    if (licenseToast) {
      const toastType = licenseToast.success ? 'success' : 'error';
      toast[toastType](licenseToast.message);
      displayToastMessage(null);
    }
  }, [licenseToast, displayToastMessage]);

  const [
    deleteSelectedLicense,
    { error: deleteLicenseError, loading: deleteLicenseLoading },
  ] = useMutation(DELETE_LICENSE, {
    refetchQueries: [
      {
        query: GET_PAGINATED_LICENSES,
        variables: {
          limit: 10,
          page: 1,
          searchTerm: '',
        },
      },
    ],
    errorPolicy: 'none',
    onCompleted: () => {
      toast.success('License deleted successfully');
      toggleDeleteModal();
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  return (
    <div className='license-screen'>
      <AccessControl
        allowedPermissions={['view:licenses_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view license page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>Licenses</h2>
          </div>
          <div className='page-header__actions'>
            <AccessControl allowedPermissions={['create:license']}>
              <NavLink to={`${url}/add`} className='btn-theme'>
                Add License
                <span>
                  <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                </span>
              </NavLink>
            </AccessControl>
          </div>
        </div>
        {openDeleteModal && (
          <DeleteLicenseModal
            modalIsOpen={openDeleteModal}
            toggleModal={toggleDeleteModal}
            modalTitle={'Delete License'}
            modalData={deleteLicense}
            error={deleteLicenseError}
            onDelete={deleteSelectedLicense}
            isLoading={deleteLicenseLoading}
          />
        )}
        <div className='grid-wrapper'>
          <LicenseGrid
            data={{
              licenses: licenses,
              total: total,
              loading,
              error,
              paginationDetails,
              next: next,
              previous: previous,
              handlePageChange,
              handleSortByColumn,
              handleDeleteLicenseSelect,
            }}
          />
        </div>
        <ToastContainer position='bottom-right' theme='colored' />
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    licenses: state.licenses.results,
    licenseToast: state.licenseToast,
    hasNext: state.licenses.hasNext,
    next: state.licenses.next,
    hasPrevious: state.licenses.hasPrevious,
    previous: state.licenses.previous,
    total: state.licenses.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setLicenseDataToStore,
  displayToastMessage,
})(
  withAuthenticationRequired(Licenses, {
    onRedirecting: () => <Loading />,
  })
);
