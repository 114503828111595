import { useLazyQuery, useMutation } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { setSharingMethodDataToStore } from 'src/States/Actions/sharingMethodAction';
import {
  ADD_SHARING_METHOD,
  GET_PAGINATED_SHARING_METHODS,
  UPDATE_SHARING_METHOD,
  UPDATE_SHARING_METHOD_STATUS,
} from 'src/Query/sharingMethods.query';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';
import Loading from '../Basic/Loading';
import SharingMethodGrid from './SharingMethods/SharingMethodGrid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import AddEditSharingMethodModal from './SharingMethods/AddEditSharingMethodModal';
import DisableSharingMethodModal from './SharingMethods/DisableSharingMethodModal';
import { LinkTypes } from 'src/Utils/types';
import QRModal from './SharingMethods/QRModal';

const SharingMethods = ({
  setSharingMethodDataToStore,
  sharingMethods,
  total,
  next,
  previous,
  searchData,
  questionnaire,
}: any) => {
  const [isEditModal, setIsEditModal] = useState(false);
  const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [selectedSharingMethod, setSelectedSharingMethod] = useState(null);
  const [openQrModal, setOpenQrModal] = useState(false);

  const toggleAddEditModal = (isEdit = false) => {
    setIsEditModal(isEdit);
    setOpenAddEditModal(!openAddEditModal);
  };

  const toggleUpdateStatusModal = () => {
    setOpenUpdateStatusModal(!openUpdateStatusModal);
  };

  const toggleQRModal = () => {
    setOpenQrModal(!openQrModal);
  };

  const handleSubmit = (params: any, isEdit = false) => {
    if (isEdit) {
      callEditAPI(params);
    } else {
      callAddAPI(params);
    }
  };

  const handleUpdateSharingMethod = (params: any) => {
    updateSharingMethodStatus(params);
  };

  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: searchData.searchTerm,
  });

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      searchTerm: searchData.search,
    });
  };

  const [loadData, { error, loading }] = useLazyQuery(
    GET_PAGINATED_SHARING_METHODS,
    {
      variables: {
        ...paginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      },
      errorPolicy: 'all',
      onCompleted: (completedData) => {
        if (completedData) {
          setSharingMethodDataToStore(completedData.sharingMethods);
        }
      },
    }
  );

  const getSuccessMessage = (sharingMethod: any, isUpdate: boolean) => {
    return `Sharing method was ${
      isUpdate ? 'updated' : 'created'
    } successfully. ${
      sharingMethod.type === LinkTypes.UNIQUE_LINK
        ? 'Unique links are generated in the background. Refresh in few seconds to receive the unique links'
        : ''
    }`;
  };

  const [callAddAPI, { error: addApiCallError, loading: addApiIsLoading }] =
    useMutation(ADD_SHARING_METHOD, {
      refetchQueries: [
        {
          query: GET_PAGINATED_SHARING_METHODS,
          variables: {
            limit: 10,
            page: 1,
            searchTerm: '',
            questionnaireId: questionnaire._id,
          },
        },
      ],
      errorPolicy: 'none',
      onCompleted: (data) => {
        const message = getSuccessMessage(data.addSharingMethod, false);
        toast.success(message);
        loadData({
          variables: {
            limit: 10,
            page: 1,
            searchTerm: '',
            questionnaireId: questionnaire._id,
          },
        });

        toggleAddEditModal();
      },
      onError: (error: any) => {
        toast.error(error.message);
        console.error(error);
      },
    });

  const [callEditAPI, { error: editApiCallError, loading: editApiIsLoading }] =
    useMutation(UPDATE_SHARING_METHOD, {
      errorPolicy: 'none',
      onCompleted: (data) => {
        const message = getSuccessMessage(data.updateSharingMethod, true);
        toast.success(message);
        loadData({
          variables: {
            limit: 10,
            page: 1,
            searchTerm: '',
            questionnaireId: questionnaire._id,
          },
        });
        toggleAddEditModal();
      },
      onError: (error: any) => {
        toast.error(error.message);
        console.error(error);
      },
    });

  const [
    updateSharingMethodStatus,
    {
      error: updateSharingMethodStatusError,
      loading: updateSharingMethodStatusLoading,
    },
  ] = useMutation(UPDATE_SHARING_METHOD_STATUS, {
    errorPolicy: 'none',
    onCompleted: () => {
      toast.success('Sharing method was updated successfully');
      loadData({
        variables: {
          limit: 10,
          page: 1,
          searchTerm: '',
          questionnaireId: questionnaire._id,
        },
      });
      toggleUpdateStatusModal();
    },
    onError: (error: any) => {
      toast.error(error.message);
      console.error(error);
    },
  });

  useEffect(() => {
    loadData({
      variables: {
        ...paginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      },
    });
  }, [loadData, paginationDetails, questionnaire, searchData]);

  useEffect(() => {
    setPaginationDetails((prevPaginationDetails: any) => {
      return {
        ...prevPaginationDetails,
        searchTerm: searchData.searchTerm,
        questionnaireId: questionnaire._id,
      };
    });
  }, [searchData, questionnaire]);

  const handleDisableBtnClick = (sharingMethod: any) => {
    setSelectedSharingMethod(sharingMethod);
    setOpenUpdateStatusModal(true);
  };

  const openEditModal = (row: any) => {
    setSelectedSharingMethod(row);
    toggleAddEditModal(true);
  };

  const handleOpenQRModal = (sharingMethod: any, isOpen = true) => {
    setSelectedSharingMethod(sharingMethod);
    setOpenQrModal(isOpen);
  };

  return (
    <div className='license-screen'>
      <AccessControl
        allowedPermissions={['view:surveys_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view survey versions'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            {/* <h2>Sharing methods aaa</h2> */}
          </div>
          <div className='page-header__actions'>
            <button
              onClick={(event: any) => {
                event.preventDefault();
                setSelectedSharingMethod(null);
                toggleAddEditModal();
              }}
              className='btn-theme'
            >
              Add sharing method
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
        {openAddEditModal && (
          <AddEditSharingMethodModal
            modalIsOpen={openAddEditModal}
            toggleModal={toggleAddEditModal}
            isEdit={isEditModal}
            modalData={{
              selectedSharingMethod,
              questionnaire,
            }}
            onSubmit={handleSubmit}
            error={addApiCallError || editApiCallError}
            isLoading={addApiIsLoading || editApiIsLoading}
          />
        )}
        {openUpdateStatusModal && (
          <DisableSharingMethodModal
            modalIsOpen={openUpdateStatusModal}
            toggleModal={toggleUpdateStatusModal}
            modalData={{
              sharingMethod: selectedSharingMethod,
              questionnaire,
            }}
            onUpdate={handleUpdateSharingMethod}
            error={updateSharingMethodStatusError}
            isLoading={updateSharingMethodStatusLoading}
          />
        )}
        {openQrModal && (
          <QRModal
            modalIsOpen={openQrModal}
            toggleModal={toggleQRModal}
            modalData={{
              sharingMethod: selectedSharingMethod,
            }}
          />
        )}
        <div className='grid-wrapper'>
          <SharingMethodGrid
            data={{
              sharingMethods,
              total,
              loading,
              error,
              paginationDetails,
              next: next,
              previous: previous,
              handlePageChange,
              handleSortByColumn,
            }}
            openQrModal={handleOpenQRModal}
            openEditModal={openEditModal}
            onOpenDisableModal={handleDisableBtnClick}
          />
        </div>
        <ToastContainer position='bottom-right' theme='colored' />
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    license: state.license,
    user: state.user,
    sharingMethods: state.sharingMethods.results,
    questionnaire: state.questionnaire,
    hasNext: state.sharingMethods.hasNext,
    next: state.sharingMethods.next,
    hasPrevious: state.sharingMethods.hasPrevious,
    previous: state.sharingMethods.previous,
    total: state.sharingMethods.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, { setSharingMethodDataToStore })(
  withAuthenticationRequired(SharingMethods, {
    onRedirecting: () => <Loading />,
  })
);
