import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-toastify';
import { customModal } from '../../Constants/ThemeO2T';

const ArchiveQuestionnaire: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  modalData,
  onArchive,
  error,
  isLoading,
}) => {
  const { name, _id } = modalData;
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        contentLabel='Archive Questionnaire Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <p>
              You are about to archive this questionnaire ({name}) from the QMS
              platform.
            </p>

            <p>Are you certain that you want to archive this Questionnaire?</p>
          </div>
          <div className='alert-wrapper'>{error && <p>{error.message}</p>}</div>
          <div className='o2t-modal__footer'>
            <button
              onClick={toggleModal}
              className='link-button mr-2'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              onClick={() => onArchive({ variables: { _id } })}
              disabled={isLoading}
            >
              Archive
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
      <ToastContainer position='bottom-right' theme='colored' />
    </>
  );
};

export default ArchiveQuestionnaire;
