import { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { ToastContainer } from 'react-toastify';

import { customModal } from '../../../Constants/ThemeO2T';
import QRCode from 'qrcode';

const QRModal: React.FC<any> = ({ modalIsOpen, toggleModal, modalData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [qr, setQr] = useState('');

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  useEffect(() => {
    QRCode.toDataURL(
      modalData?.sharingMethod?.link,
      { width: 350 },
      (error, url) => {
        if (error) return console.error(error);
        setQr(url);
      }
    );
  }, [modalData]);

  return (
    <>
      <Modal isOpen={isOpen} style={customModal} contentLabel='QR Modal'>
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>
              {modalData.sharingMethod?.name} QR Code
            </h2>
          </div>
          <div className='o2t-modal__container'>
            <div className='o2t-modal__qr-container'>
              {qr && (
                <>
                  <img alt='QR Code' src={qr} />
                </>
              )}
            </div>
          </div>
          <div className='o2t-modal__footer'>
            <button onClick={toggleModal} className='link-button mr-2'>
              Cancel
            </button>
            <a
              className='link-btn mr-2'
              download={`${modalData?.sharingMethod?.name} QR.png`}
              href={qr}
            >
              Download
            </a>
          </div>
        </div>
      </Modal>
      <ToastContainer position='bottom-right' theme='colored' />
    </>
  );
};

export default QRModal;
