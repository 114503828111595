import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faFileSignature,
  faHome,
  faUserFriends,
  faIdBadge,
  faPalette,
  faPoll,
  faLink,
  faChartLine,
} from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSearchData } from '../../States/Actions/searchAction';
import AccessControl from '../AccessControl';
import Feature from '../Feature';

const StickyNav = (props: any) => {
  const [isNavOpen, setNav] = useState(false);
  const [isProductDropdownOpen, setProductDropdownOpen] = useState(false);

  const toggleProductDropdown = () => {
    setProductDropdownOpen(!isProductDropdownOpen);
  };

  const location = useLocation();

  const handleNav = () => {
    setNav((prevState) => !prevState);
  };

  useEffect(() => {
    props.setSearchData({
      searchTerm: '',
      isOpen: false,
    });
  }, [location, props]);

  return (
    <div className={`sticky-nav ${isNavOpen ? 'nav-open' : ''}`}>
      <div className='blur-overlay' onClick={handleNav} />
      <div className='sticky-nav__wrapper'>
        <nav className='sticky-nav__navbar'>
          <ul className='sticky-nav__nav-list'>
            <li className='sticky-nav__nav-item nav-btn-wrapper'>
              <button
                onClick={handleNav}
                className='sticky-nav__nav-link menu-bars'
              >
                <FontAwesomeIcon icon={faBars} />
              </button>
            </li>
            <AccessControl allowedPermissions={['view:dashboard_page']}>
              <li className='sticky-nav__nav-item'>
                <NavLink exact={true} to='/' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faHome} />
                  </span>
                  <span className='nav-label'>Home</span>
                </NavLink>
              </li>
            </AccessControl>
            <AccessControl allowedPermissions={['view:licenses_page']}>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/licenses' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faIdBadge} />
                  </span>
                  <span className='nav-label'>Licenses</span>
                </NavLink>
              </li>
            </AccessControl>
            <AccessControl allowedPermissions={['view:licenses_page']}>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/link-data-sets' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faLink} />
                  </span>
                  <span className='nav-label'>Link Data Sets</span>
                </NavLink>
              </li>
            </AccessControl>
            <AccessControl allowedPermissions={['view:surveys_page']}>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/questionnaires' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faFileSignature} />
                  </span>
                  <span className='nav-label'>Questionnaires</span>
                </NavLink>
              </li>
            </AccessControl>
            <Feature name='styleTemplates'>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/templates' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faPalette} />
                  </span>
                  <span className='nav-label'>Style Templates</span>
                </NavLink>
              </li>
            </Feature>
            <AccessControl allowedPermissions={['view:users_page']}>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/users' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faUserFriends} />
                  </span>
                  <span className='nav-label'>Users</span>
                </NavLink>
              </li>
            </AccessControl>

            <li
              className='sticky-nav__nav-item'
              onClick={() => toggleProductDropdown()}
            >
              <NavLink to='/products' className='sticky-nav__nav-link'>
                <span className='icon-wrapper'>
                  <FontAwesomeIcon icon={faPoll} />
                </span>
                <span className='nav-label'>Products</span>
              </NavLink>
            </li>
            {isProductDropdownOpen && (
              <div className='sub-menu'>
                <li
                  className='sticky-nav__nav-item'
                  onClick={() => toggleProductDropdown()}
                >
                  <NavLink
                    to='/products/questionnaires'
                    className='sticky-nav__nav-link'
                  >
                    <span
                      className='icon-wrapper'
                      style={{ marginLeft: isNavOpen ? '2rem' : '' }}
                    >
                      <FontAwesomeIcon icon={faFileSignature} />
                    </span>
                    <span className='nav-label'>Questionnaire</span>
                  </NavLink>
                </li>
                <li
                  className='sticky-nav__nav-item'
                  onClick={() => toggleProductDropdown()}
                >
                  <NavLink
                    to='/products/templates'
                    className='sticky-nav__nav-link'
                  >
                    <span
                      className='icon-wrapper'
                      style={{ marginLeft: isNavOpen ? '2rem' : '' }}
                    >
                      <FontAwesomeIcon icon={faPalette} />
                    </span>
                    <span className='nav-label'>Style Templates</span>
                  </NavLink>
                </li>
              </div>
            )}
            <Feature name='smartAnalytics'>
              <li className='sticky-nav__nav-item'>
                <NavLink to='/analytics' className='sticky-nav__nav-link'>
                  <span className='icon-wrapper'>
                    <FontAwesomeIcon icon={faChartLine} />
                  </span>
                  <span className='nav-label'>Smart Analytics</span>
                </NavLink>
              </li>
            </Feature>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default connect(null, { setSearchData })(StickyNav);
