import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import reportWebVitals from './reportWebVitals';
import { store, persister } from './States';
import history from './Constants/history';
import AuthorizedApolloProvider from './Services/Server/AuthorizedApolloProvider';

import 'react-datepicker/dist/react-datepicker.css';
import './Styles/Index.scss';
import { PersistGate } from 'redux-persist/integration/react';

const appEnv: string = process.env.REACT_APP_ENV || 'local';
const sentryAppEnvs = ['development', 'staging', 'production'];
const logAnalyticsAppEnvs = ['local', 'development'];
const auth0Domain: string = process.env.REACT_APP_AUTH0_DOMAIN || '';
const auth0ClientId: string = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE || '';

const onRedirectCallback = (appState: any) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};

const providerConfig = {
  domain: auth0Domain,
  clientId: auth0ClientId,
  audience: auth0Audience,
  redirectUri: window.location.origin,
  onRedirectCallback,
};

const replaysSessionSampleRate = appEnv === 'production' ? 0.1 : 1.0;

if (sentryAppEnvs.includes(appEnv)) {
  Sentry.init({
    dsn: 'https://0e5c8d75d118473eb3cbe3358ce7ba3f@o1017208.ingest.sentry.io/5982899',
    integrations: [new Integrations.BrowserTracing(), new Sentry.Replay()],
    environment: appEnv,
    release: `qms-client@${process.env.REACT_APP_RELEASE_VERSION}`,
    tracesSampleRate: 1.0,
    replaysSessionSampleRate,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Auth0Provider {...providerConfig} cacheLocation='localstorage'>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persister}>
          <AuthorizedApolloProvider>
            <App />
          </AuthorizedApolloProvider>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
if (logAnalyticsAppEnvs.includes(appEnv)) {
  reportWebVitals();
}
